import React, { useState } from 'react'
import { Box, Button, CardMedia, Container, Grid, Typography, Card, InputLabel, TextField, Stack, Autocomplete, Checkbox } from '@mui/material'

import CallIcon from '@mui/icons-material/Call';
import TextsmsIcon from '@mui/icons-material/Textsms';
import MailIcon from '@mui/icons-material/Mail';
import { Toaster, toast } from "react-hot-toast";



// import ContactRightVdo from "../../assets/videos/Mail.mp4"

import contactLeft from "../../assets/images/Locations/contact-vector.png"
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';

const styles = {

    contactFrom: {
        display: 'flex', flexDirection: 'column', gap: ' 26px',
    },

    ContactRight: {

    },
    contactFromVideo: {
        width: "62%", margin: 'auto',
    },
    cntctlft: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
    },
    cntctlfticon: {
        color: 'var(--primary)',
        fontSize: '50px',
    },
    cntcth2: {
        color: 'var(--primary) !important',
        textTransform: 'uppercase',
        fontWeight: '500 !important',
        fontSize: '16px !important',
    },
    cntctBox: {
        display: 'flex',
        gap: '15px',
        border: ' 1px solid #00000005',
        padding: '20px 40px',
        borderRadius: '5px',
        boxShadow: '2px 2px 10px #0000004d',
        width: '30%',
        '@media (max-width: 600px)': {
            width: '100% !important',
        }
    }

}

const courses = [
    {
        id: 1,
        label: 'Behind the Wheel'
    },
    {
        id: 2,
        label: 'DUI'
    },
    {
        id: 3,
        label: 'Defensive'
    },
    {
        id: 4,
        label: 'Drivers Education'
    },
    {
        id: 5,
        label: 'Road testing'
    }
]



const LContact = () => {
    const [MyProductId, setMyProductId] = useState('')


    //  api integration

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        address: '',
        locationid: [],
        message: ''
    });


    // console.log(`epic form get in touch dat 🔥🔥🔥 - `, formData);




    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        if (formData?.firstname == "") {
            return toast.error('Please enter First Name')
        }
        else if (formData?.lastname == "") {
            return toast.error('Please enter last Name')
        }
        else if (formData?.email == "") {
            return toast.error('Please enter email')
        }
        else if (formData?.mobile == "") {
            return toast.error('Please enter mobile number')
        }
        else if (formData?.address == "") {
            return toast.error('Please enter address')
        }
        else if (formData?.locationid?.length === 0) {
            return toast.error('Please choose atleast one course')
        }
        else if (formData?.message == "") {
            return toast.error('Please enter message')
        }
        else {
            try {

                const res = await axios.post(`${base_Url}/contactform`, {
                    firstname: formData?.firstname,
                    lastname: formData?.lastname,
                    email: formData?.email,
                    contactno: formData?.mobile,
                    address: formData?.address,
                    course: formData?.locationid,
                    message: formData?.message
                })
                if (res.data.status == true) {
                    setFormData({
                        firstname: '',
                        lastname: '',
                        email: '',
                        mobile: '',
                        address: '',
                        locationid: null,
                        message: ''
                    })
                    toast.success('Submitted Successfully')
                }
                else {
                    toast.error('unable to submit')
                }

            } catch (error) {
                console.log(`error in handleSubmit - `, error);
            }
        }
    }





    return (
        <>

            <Toaster />

            <Box>
                <Container>
                    {/* <Typography variant='h1' sx={{}}>Locat</Typography> */}

                    <Grid container spacing={2} sx={{ margin: '60px 0px 30px', gap: '20px', justifyContent: 'center', }}>
                        <Box sx={styles.cntctBox} onClick={() => (window.location.href = 'tel:770-274-4223')}>
                            <CallIcon sx={styles.cntctlfticon} />
                            <Grid>
                                <Typography variant='h2' sx={styles.cntcth2}>Call</Typography>
                                <Typography>770-274-4223</Typography>
                            </Grid>
                        </Box>
                        <Box sx={styles.cntctBox} onClick={() => (window.location.href = 'sms:404-590-4570')}>
                            <TextsmsIcon sx={styles.cntctlfticon} />
                            <Grid>
                                <Typography variant='h2' sx={styles.cntcth2}>Text</Typography>
                                <Typography>404-590-4570</Typography>
                            </Grid>
                        </Box>
                        <Box sx={styles.cntctBox} onClick={() => (window.location.href = 'mailto:info@1actdrivingschools.com')}>
                            <MailIcon sx={styles.cntctlfticon} />
                            <Grid>
                                <Typography variant='h2' sx={styles.cntcth2}>Email</Typography>
                                <Typography>info@1actdrivingschools.com</Typography>
                            </Grid>
                        </Box>
                    </Grid>

                    <Card sx={{ p: 4, marginTop: '20px', boxShadow: '3px 4px 11px #0000005e !important', }}>

                        <Grid container spacing={2} alignItems='center'>

                            <Grid item md={6} xs={12} >
                                <CardMedia component="img" src={contactLeft} sx={{ width: '60%', margin: '20px auto' }} />
                            </Grid>

                            <Grid item xs={12} md={6}
                                sx={styles.contactFrom}
                            >

                                <Typography variant='h2'>Get in touch</Typography>
                                <Grid container spacing={2}>


                                    <Grid item xs={12} md={6}>
                                        <Stack>
                                            {/* <InputLabel>Name</InputLabel> */}
                                            <TextField size="small" type="text" name="firstname" value={formData?.firstname} onChange={handleChange} label='First name' />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Stack>
                                            {/* <InputLabel>Email</InputLabel> */}
                                            <TextField size="small" type="text" name="lastname" value={formData?.lastname} onChange={handleChange} label='Last name' />
                                        </Stack>
                                    </Grid>


                                    <Grid item xs={12} md={6}>
                                        <Stack>
                                            {/* <InputLabel>Name</InputLabel> */}
                                            <TextField size="small" type="email" name="email" value={formData?.email} onChange={handleChange} label='Email' />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Stack>
                                            {/* <InputLabel>Email</InputLabel> */}
                                            <TextField size="small" type="number" name="mobile" value={formData?.mobile} onChange={handleChange} label='Mobile No' />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Stack>
                                            {/* <InputLabel>Email</InputLabel> */}
                                            <TextField size="small" type="text" name="address" value={formData?.address} onChange={handleChange} label='Address' />
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Stack>

                                            <Autocomplete
                                                fullWidth
                                                multiple
                                                color='primary'
                                                id="locationid"
                                                name='locationid'
                                                value={courses.filter(option => (formData.locationid || []).includes(option.id)) || []} 
                                                // value={courses?.filter((option) => formData?.includes(option?.id))}
                                                // value={formData?.locationid}
                                                options={courses ? courses : []}
                                                variant="outlined"
                                                disablePortal
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, val) => {
                                                    if (val) {
                                                        console.log('val', val);
                                                        setFormData((prev) => {
                                                            return {
                                                                ...prev,
                                                                locationid: val.map((option) => option.id)
                                                            }
                                                        })
                                                        // setApiBranchID(val.map((option) => option.id));
                                                    }
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li key={option.id} {...props}>
                                                        <Checkbox
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.label}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Select courses" size='small' color='primary' />
                                                )}
                                                autoHighlight={true}
                                                ChipProps={{
                                                    color: 'primary',
                                                }}

                                            />

                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Stack>
                                            {/* <InputLabel>Message</InputLabel> */}
                                            <TextField size="small" type="text" name="message" value={formData?.message} onChange={handleChange} multiline rows={2.6} label="Message" />
                                        </Stack>
                                    </Grid>




                                    <Grid item xs={12} sx={{ display: 'flex !important', justifyContent: 'flex-end !important', mt: 2, float: 'right !important' }}>

                                        <Button href='' variant="contained" onClick={handleSubmit} sx={{ float: 'right !important', display: 'flex !important', justifyContent: 'flex-end !important' }}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Card>

                </Container>
            </Box>

        </>
    )
}

export default LContact
