
import React, { useMemo } from 'react';
import { Slide, DialogTitle, DialogContent, Typography, DialogActions, Checkbox, Button, FormControlLabel, Dialog, Stack, IconButton, Box } from '@mui/material';

import { IoMdCloseCircleOutline } from "react-icons/io";


import { styled } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { Padding } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const AnimatedDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        animation: `$slideIn 5s`,
        "@keyframes slideIn": {
            "0%": {
                transform: "translateX(-100%)",
            },
            "100%": {
                transform: "translateX(0)",
            },
        },
        padding: '2mm !important',
        // border: '2px solid black !important',
    },
});



const UpsellingDialog = ({
    open,
    handleClose,
    selectedBTWHour,
    dontShowAgain,
    handleCheckboxChange,
}) => {

    // Memoize dialog content based on `selectedBTWHour` and `dontShowAgain`
    const dialogContent = useMemo(() => {
        if (selectedBTWHour === 2 || selectedBTWHour === 4) {
            return (
                <Typography>
                    Unlock Your Full Potential! Upgrade to 6 hours of BTW classes and grab
                    Drivers Education for just $95 <br />(save $29!). More hours, more confidence,
                    and bigger savings – get started today!
                </Typography>
            );
        } else if (selectedBTWHour === 8 || selectedBTWHour === 10) {
            return (
                <Typography>
                    Maximize your learning! Upgrade to 12 hours of BTW classes and get Defensive Driving absolutely FREE!
                    Don’t miss out on this exclusive offer!
                </Typography>
            );
        } else {
            return null;
        }
    }, [selectedBTWHour]);


    const navigate = useNavigate()
    return (
        <AnimatedDialog
            open={open}
            // onClose={handleClose}
            aria-labelledby="custom-dialog-title"
            TransitionComponent={Transition}
            transitionDuration={{ enter: 200, exit: 200 }}
            keepMounted
            fullWidth
            maxWidth='md'
        >
            <div style={{ border: '2px solid #0063d3', borderRadius: '5px' }}>
                <DialogTitle id="custom-dialog-title">


                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                        <Typography sx={{ color: 'red !important', textAlign: 'center', flexGrow: 1 }} variant="h5">
                            Limited Time Offer!
                        </Typography>

                        <IconButton sx={{ fontSize: '30px', color: 'black' }} variant="outlined" color='error' onClick={handleClose} >
                            <IoMdCloseCircleOutline sx={{ fontSize: '30px' }} />
                        </IconButton>

                    </Stack>
                </DialogTitle>
                <DialogContent>


                    <Box sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ color: 'black !important', textAlign: 'center' }}>
                            Receive a <b style={{ textDecoration: 'underline' }}>Free</b> On-site Road Test with a purchase of <b style={{ textDecoration: 'underline' }}>10 Hours or More </b>Behind the Wheel Driving Lessons
                        </Typography>
                        <br />
                        <Typography sx={{ color: 'blue !important', textAlign: 'center' }}>
                            Driving Lessons <b style={{ textDecoration: 'underline' }}>Must </b>be purchased between <b style={{ textDecoration: 'underline' }}>"September 12, 2024 through November 13, 2024"</b>
                        </Typography>
                        <br />
                        <Typography sx={{ color: 'black !important', textAlign: 'center' }}>
                            Purchase Driving Lessons by clicking below and <b>Call 770-274-4223 </b>to avail Road Test for Free.
                        </Typography>
                        <br />
                        {/* {dialogContent} */}
                        {/* <Button variant="contained" color="error" onClick={() => navigate("/schedule-driving-lessons-2/2")} sx={{ mt: 2 }}>BUY NOW</Button> */}
                    </Box>

                </DialogContent>

                {/* <DialogActions> */}
                {/* <FormControlLabel
                    control={
                        <Checkbox
                            checked={dontShowAgain}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Do not show again"
                /> */}
                {/* <Button variant="contained" color="error" onClick={handleClose}>
                    Close
                </Button> */}
                {/* </DialogActions> */}

            </div>
        </AnimatedDialog>
    );
};

export default UpsellingDialog;
