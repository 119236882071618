import React, { useEffect, useState, useCallback } from 'react';
import { Grid, TextField, InputLabel, IconButton, Card, Stack, Button, FormControl, Box, Autocomplete, Typography, Divider, CircularProgress } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Toaster, toast } from "sonner";
import { useTheme } from '@mui/material/styles';
import MainCard from '../../components/MainCard';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import Register from './Register';
import CloseIcon from '@mui/icons-material/Close';
import { MuiPhone } from './MuiPhone';
import { ParentPhone } from './ParentPhone';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

const success = (data) => {
    toast.success(data, {
        position: 'top-center',
        style: { backgroundColor: 'green', color: 'white' },

        cancel: {
            label: "close",
            onClick: () => console.log('Cancel!'),
        },
    });
}

const failure = (data) => {
    toast.error(data, {
        position: 'top-center',
        style: { border: '1px solid red' },
        duration: 3000,
        cancel: {
            label: "x",
            // style: {
            //     backgroundColor: '#a8261d', 
            //     color: '#ffffff', 
            //     border: 'none', 
            //     padding: '2px 8px',
            //     borderRadius: '4px', 
            // },
            onClick: () => console.log('Cancel!'),
        },

    });
}

const RegisterForm = ({ handleNext, handleBack, formData, setFormData, setActiveStep, coursedetails, setRegisterStatus, finalData }) => {
    const theme = useTheme();


    console.log('formData.phonenumber', formData.phonenumber)

    const roadTest = coursedetails?.some(course => course.type === 5);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [loginOpen, setLoginOpen] = useState(false)
    const handleEmailChange = (e) => {
        const { name, value } = e.target;

        // Update the form state immediately
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Call the debounced function
        checkEmailDebounced(value);
    };

    // Debounce API call to prevent too many requests on each keystroke
    const checkEmailDebounced = useCallback(
        debounce(async (email) => {
            try {
                const response = await axios.post(`${base_Url}/alreadyStudentExist`, { email });
                if (response.data.status === false) {
                    setFormData((prevData) => ({
                        ...prevData,
                        email: email,
                    }));
                } else {
                    toast.error(response.data.message);
                    setLoginOpen(true);
                    window.history.replaceState(null, null, window.location.pathname);
                }
            } catch (error) {
                console.log('error', error);
            }
        }, 2000), // Debounce time (in milliseconds)
        []
    );



    // const handleDateChange = (e) => {
    //     const { value } = e.target;

    //     // Default value from type="date" is yyyy-mm-dd
    //     const [year, month, day] = value.split('-');

    //     // Limit the year to 4 digits
    //     if (year.length > 4) {
    //         e.target.value = `${year.slice(0, 4)}-${month}-${day}`;
    //     }

    //     // Convert to dd-mm-yyyy format
    //     const formattedDate = `${day}-${month}-${year.slice(0, 4)}`;

    //     // Update state
    //     setFormData({
    //         ...formData,
    //         Dob: formattedDate,
    //     });
    // };



    //  ================================= Plan A =============================================

    const handleDateChange = (newValue) => {
        // Format the date to mm-dd-yyyy
        const formattedDate = dayjs(newValue).format('MM-DD-YYYY');

        setFormData({
            ...formData,
            Dob: formattedDate, // Save the formatted date in formData
        });
    };

    //  ================================= Plan A ===============================================

    //  ================================== Plan B ===============================================
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);

    // const handleDateChange = () => {
    //     if (selectedMonth || selectedDay || selectedYear) {

    //         const formattedDate = dayjs(`${selectedYear}-${selectedMonth}-${selectedDay}`).format('MM-DD-YYYY');

    //         console.log(`formattedDate - `, formattedDate);


    //         setFormData({
    //             ...formData,
    //             Dob: formattedDate,
    //         });
    //     }
    // };

    // Update month, day, or year and trigger handleDateChange
    const handleMonthChange = (newValue) => {
        setSelectedMonth(dayjs(newValue).format('MM'));
        handleDateChange();
    };

    const handleDayChange = (newValue) => {
        setSelectedDay(dayjs(newValue).format('DD'));
        handleDateChange();
    };

    const handleYearChange = (newValue) => {
        setSelectedYear(dayjs(newValue).format('YYYY'));
        handleDateChange();
    };
    //  ============================================ Plan B =========================================




    console.log('formData in register form', formData)

    const dob = new Date(formData.Dob);
    const isBelow18 = (new Date().getFullYear() - dob.getFullYear()) <= 18;
    console.log("Is below 18:", isBelow18);



    const finalcheckisexistUser = async () => {
        try {
            const response = await axios.post(`${base_Url}/alreadyStudentExist`, { email: formData?.email });
            if (response.data.status === false) {
                return true;
            } else {
                failure(response.data.message);
                setLoginOpen(true);
                window.history.replaceState(null, null, window.location.pathname);
                return false;
            }
        } catch (error) {
            console.log('error', error);
            return false
        }
    }

    const NextClick = async () => {
        const checking = await finalcheckisexistUser()

        console.log('checking>>>', checking)




        // Validation for each field

        // failure('Date of birth is required')

        // checkEmailDebounced(formData.email);
        if (!formData.firstname) {
            failure('First name is required ',);
            return;
        }

        if (!formData.lastname) {
            failure('Last name is required');
            return;
        }

        if (!formData.email) {
            failure('Email address is required');
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            failure('Invalid email address');
            return;
        }


        console.log(`epic phone number - `, formData.phonenumber);


        if (!formData.phonenumber) {
            failure('Please enter your mobile number.');
            return;
        }

        // const phoneNumberRegex = /^(?=.*\d.*\d.*\d.*\d).+$/;
        if (formData?.phonenumber?.length != 12) {
            failure('Please enter Valid 10 digit mobile number.');
            return;
        }

        if (!formData.Dob) {
            failure('Please provide your date of birth',);
            return;
        }



        // if (!formData.unitNo) {
        //     failure('Please provide your suite number');
        //     return;
        // }

        if (!formData.billingaddress) {
            failure('Billing address is required');
            return;
        }

        if (!formData.newcity) {
            failure('City is required');
            return;
        }

        if (!formData.newstate) {
            failure('State is required');
            return;
        }

        if (!formData.newcountry) {
            failure('Country is required');
            return;
        }

        if (!formData.zipcode) {
            failure('Zip code is required');
            return;
        }


        if (isBelow18) {
            if (!formData.parentname) {
                failure(`(for students under 18): Parent's name is required`);
                return;
            }

            if (!formData.parentemail) {
                failure(`(for students under 18): Parent's email address is required`);
                return;
            }

            if (!formData.parentphone) {
                failure(`(for students under 18): Parent's mobile number is required`);
                return;
            }

            if (!formData.relation) {
                failure(`(for students under 18): Please specify the relationship to the student`);
                return;
            }

        }

        let isValid = true;

        coursedetails?.forEach(product => {
            if (product?.type !== 5 && product.type !== 1 && !product.addonid) {
                failure(`Please select a slot for ${product.productname}`);
                isValid = false;
                return;
            }


            if (product?.type !== 5 && product.type !== 1 && product.type !== 4 && !product?.classmode) {
                failure(`Please select the class mode for ${product.productname}`)
                isValid = false;
                return;
            }


            if (product?.classmode == 2) {
                if (!product?.locationid) {
                    failure(`(for in-person classes): Please select the location ${product.productname}`)
                    isValid = false;
                }
            }
        });

        if (isValid) {
            if (checking) {
                handleNext(roadTest ? 1 : 2)
            }

            // setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    };



    const models = [
        {
            id: 1,
            label: 'Father'
        },
        {
            id: 2,
            label: 'Mother'
        },
        {
            id: 3,
            label: 'Guardian'
        }
    ]

    const genderArray = [
        {
            id: 1,
            label: 'Male'
        },
        {
            id: 2,
            label: 'Female'
        },
        {
            id: 3,
            label: 'Not to disclose'
        }
    ]





    const [countryInfo, setCountryInfo] = useState([]);
    const [countryLoading, setCountryLoading] = useState(true)
    const fetchCountryInfo = async () => {
        try {
            const response = await axios.get(`https://countriesnow.space/api/v0.1/countries/iso`);
            const data = response?.data?.data;
            const result = data.map((item) => ({
                id: item.Iso2,
                label: item.name
            }));

            setCountryInfo(result);
            setCountryLoading(false)
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };






    const [stateInfo, setStateInfo] = useState([]);
    const [stateLoading, setStateLoading] = useState(false)
    const fetchState = async (id) => {

        try {
            setStateInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/states`, {
                "country": id
            }
            );
            const data = response?.data?.data?.states;
            const result = data.map((item) => ({
                id: item.state_code,
                label: item.name
            }));
            setStateLoading(false)
            setStateInfo(result);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };



    const [cityInfo, setCityInfo] = useState([]);
    const [cityLoading, setCityLoading] = useState(false)
    const fetchCity = async (selectedStateId, country) => {

        try {
            setCityInfo([])
            const response = await axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, {
                "country": `${country}`,
                "state": `${selectedStateId}`
            });
            const data = response.data.data;
            const result = data?.map((item) => ({
                id: item,
                label: item
            }));
            setCityInfo(result);
            setCityLoading(false)
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        fetchCountryInfo()
        setFormData({
            ...formData,
            ['newstate']: 'Georgia',
        });
    }, []);


    useEffect(() => {


        if (formData?.newcountry) {
            fetchState(formData?.newcountry)
        }

        if (formData?.newcountry && formData?.newstate) {
            fetchCity(formData?.newstate, formData?.newcountry)
        }
    }, [formData?.newcountry]);



    // console.log(`🔥🔥🔥😊😊 - `, formData.Dob.split('-').reverse().join('-'))





    return (
        <div
            style={{ margin: '0px 10px 10px 10px' }}
        >
            <Toaster
                toastOptions={{
                    duration: 3000,
                    style: {
                        cursor: 'pointer',

                    },
                    // Clickable setting ensures that clicking the toast will close it
                    ariaLive: 'assertive',
                    position: 'top-center',

                }}
            />


            <Register
                openStatus={loginOpen}
                setRegisterStatus={setLoginOpen}
                finalData={finalData}
            />


            <MainCard content={false} sx={{
                mt: 2,
            }}>

                <Grid container spacing={2} sx={{ p: 2, }}>
                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleChange}
                                label='First name'
                                placeholder='First name'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleChange}
                                label='Last name'
                                placeholder='Last name'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleEmailChange}
                                label='Email'
                                placeholder='Email'
                            />
                        </Stack>
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="number"
                                name="phonenumber"
                                value={formData.phonenumber}
                                onChange={handleChange}

                                label='Mobile number'
                                placeholder='Mobile number'
                            />
                        </Stack>
                    </Grid> */}



                    <Grid item xs={12} md={6}>
                        <MuiPhone
                            formValues={formData}
                            setFormValues={setFormData}

                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack>

                            {/* this old Date of birth  */}
                            {/* <TextField
                                required
                                size="small"
                                type="date"
                                name="Dob"
                                value={formData.Dob.split('-').reverse().join('-')} 
                                onChange={handleDateChange}
                                sx={{ mt: 1 }}
                                label="Date of Birth"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            /> */}
                            {/* this old Date of birth  */}


                            <LocalizationProvider dateAdapter={AdapterDayjs}>


                                {/* ====================== Plan A ====================== */}

                                <DatePicker
                                    label="Date Of Birth"
                                    value={formData.Dob ? dayjs(formData.Dob, 'MM-DD-YYYY') : null} // Ensures null is passed if no value
                                    onChange={handleDateChange}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ margin: '8px 0 0 0' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            error={false}
                                            sx={{
                                                '& .MuiOutlinedInput-root.Mui-error': {
                                                    '& fieldset': {
                                                        borderColor: 'grey !important',
                                                    },
                                                },
                                            }}
                                        />
                                    )}
                                />

                                {/* ====================== Plan A ====================== */}


                                {/* ====================== Plan B ====================== */}


                                {/* <Stack direction={'row'} spacing={1}>
                                 
                                    <DatePicker
                                        label="Month"
                                        views={['month']}
                                        value={selectedMonth ? dayjs().month(selectedMonth - 1) : null}
                                        onChange={handleMonthChange}
                                        renderInput={(params) => (
                                            <TextField {...params} fullWidth size="small" />
                                        )}
                                    />

                                   
                                    <DatePicker
                                        label="Day"
                                        views={['day']}
                                        value={selectedDay ? dayjs().date(selectedDay) : null}
                                        onChange={handleDayChange}
                                        renderInput={(params) => (
                                            <TextField {...params} fullWidth size="small" />
                                        )}
                                    />

                                   
                                    <DatePicker
                                        label="Year"
                                        views={['year']}
                                        openTo="year"
                                        value={selectedYear ? dayjs().year(selectedYear) : null}
                                        onChange={handleYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} fullWidth size="small" />
                                        )}
                                    />
                                </Stack> */}

                                {/* ====================== Plan B ====================== */}


                            </LocalizationProvider>

                        </Stack>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Stack>

                            <Autocomplete
                                id="model"
                                options={genderArray ? genderArray : []}
                                value={genderArray?.find((option) => option?.label == formData?.gender) || null}
                                // getOptionLabel={(option) => option.modelname}
                                onChange={(event, val) => {
                                    if (val) {
                                        console.log('val', val)
                                        const updatedData = { ...formData }
                                        // updatedData['relation'] = val.id
                                        updatedData['gender'] = val.label
                                        setFormData(updatedData)
                                    } else {
                                        const updatedData = { ...formData }
                                        // updatedData['relation'] = val.id
                                        updatedData['gender'] = ''
                                        setFormData(updatedData)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={"Gender"} size='small'
                                        label={"Gender"} required

                                    />
                                )}
                                sx={{ mt: 1 }}
                                autoHighlight={true}
                            />
                        </Stack>
                    </Grid>




                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField

                                size="small"
                                type="text"
                                name="licenseno"
                                value={formData.licenseno}
                                onChange={handleChange}
                                label='License No'
                                placeholder='License No'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField

                                size="small"
                                type="text"
                                name="licensestate"
                                value={formData.licensestate}
                                onChange={handleChange}
                                label='License state'
                                placeholder='License state'
                            />
                        </Stack>
                    </Grid>





                    <Grid item xs={12} md={2}>
                        <Stack>

                            <TextField
                                size="small"
                                type="unitNo"
                                name="unitNo"
                                value={formData.unitNo}
                                onChange={handleChange}
                                label='Suite/APT No'
                                placeholder='Suite/APT No'
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={10}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="billingaddress"
                                value={formData.billingaddress}
                                onChange={handleChange}
                                label=' Address'
                                placeholder=' Address'
                            />
                        </Stack>
                    </Grid>








                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            id="cityInfo"
                            options={cityInfo ? cityInfo : []}
                            loading={cityLoading}
                            getOptionLabel={(option) => option.label}
                            value={cityInfo?.find((option) => option?.label == formData?.newcity) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setFormData({
                                        ...formData,
                                        ['newcity']: value?.label,
                                    });
                                } else {
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newcity: '',
                                    }));
                                }

                            }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder="City name" label='City' size='small'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {cityLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}

                                />
                            )}
                            autoHighlight={true}
                        />
                    </Grid>


                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="state-input"
                            loading={stateLoading}
                            options={stateInfo || []}
                            value={stateInfo?.find((option) => option?.label === formData?.newstate) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setCityLoading(true)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newstate: value?.label,
                                        newcity: '', // Clear the city when state changes
                                    }));
                                    fetchCity(value?.label, formData?.newcountry);
                                } else {

                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newstate: '',
                                        newcity: '', // Clear the city when state is cleared
                                    }));
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="State" size="small"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {stateLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>

                    <Grid item xs={12} md={6}>

                        <Autocomplete
                            id="country-input"
                            loading={countryLoading}
                            options={countryInfo || []}
                            value={countryInfo?.find((option) => option?.label === formData?.newcountry) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setStateLoading(true)
                                    // setCityLoading(true)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newcountry: value?.label,
                                        newcity: '',
                                        newstate: '',
                                    }));
                                    fetchState(value?.label);
                                } else {
                                    // setStateLoading(true)
                                    setFormData((prevFormData) => ({
                                        ...prevFormData,
                                        newcountry: '',
                                        newstate: '',
                                        newcity: '',
                                    }));
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Country" size="small"

                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {countryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            autoHighlight
                        />

                    </Grid>




                    <Grid item xs={12} sm={6}>
                        <Stack>

                            <TextField
                                required
                                size="small"
                                type="text"
                                name="zipcode"
                                value={formData.zipcode}
                                onChange={handleChange}
                                label='Zip code'
                                placeholder='Zip code'
                            />
                        </Stack>
                    </Grid>


                </Grid>

            </MainCard>



            <MainCard content={false} sx={{
                mt: 2,
            }}
            >
                <Stack direction={'row'} spacing={1} sx={{ p: 1 }}>
                    <Typography >
                        Parent Details
                    </Typography>
                    <Typography sx={{ color: 'gray !important' }}>
                        {!isBelow18 ? "(optional)" : ""}
                    </Typography>
                </Stack>

                <Divider />

                <Grid container spacing={2} sx={{ p: 2, }} >
                    <>


                        <Grid item xs={12} sm={6}>
                            <Stack>
                                {/* <InputLabel>Parent Name</InputLabel> */}
                                <TextField
                                    size="small"
                                    type="text"
                                    name="parentname"
                                    value={formData.parentname}
                                    onChange={handleChange}
                                    label={!isBelow18 ? " Name " : " Name"}
                                    required={!isBelow18 ? false : true}
                                    placeholder=' Name'
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>
                                {/* <InputLabel>Parent Email</InputLabel> */}
                                <TextField
                                    size="small"
                                    type="text"
                                    name="parentemail"
                                    value={formData.parentemail}
                                    onChange={handleChange}
                                    required={!isBelow18 ? false : true}
                                    label={!isBelow18 ? "Email" : " Email"}
                                    placeholder='Email'
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Stack>

                                {/* <TextField
                                    size="small"
                                    type="number"
                                    name="parentphone"
                                    value={formData.parentphone}
                                    onChange={handleChange}
                                    label={!isBelow18 ? "Mobile No " : "Mobile No"}
                                    placeholder='Mobile No'
                                    required={!isBelow18 ? false : true}
                                /> */}

                                <ParentPhone
                                    formValues={formData}
                                    setFormValues={setFormData}

                                />
                            </Stack>
                        </Grid>


                        <Grid item xs={12} sm={6}>
                            <Stack>
                                {/* <InputLabel>Relation</InputLabel> */}
                                <Autocomplete
                                    id="model"
                                    options={models ? models : []}
                                    value={models?.find((option) => option?.label == formData?.relation) || null}
                                    // getOptionLabel={(option) => option.modelname}
                                    onChange={(event, val) => {
                                        if (val) {
                                            console.log('val', val)
                                            const updatedData = { ...formData }
                                            // updatedData['relation'] = val.id
                                            updatedData['relation'] = val.label
                                            setFormData(updatedData)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={"Relation"} size='small'
                                            label={!isBelow18 ? "Relation " : "Relation"}
                                            required={!isBelow18 ? false : true}

                                        />
                                    )}
                                    // sx={{ mt: 1 }}
                                    autoHighlight={true}
                                />
                            </Stack>
                        </Grid>







                    </>
                </Grid>

            </MainCard>

            <Stack sx={{ m: 2 }} justifyContent="flex-end" direction="row">
                <Button variant="contained" onClick={() => NextClick()}>Next</Button>
            </Stack>
        </div>
    );
};

export default RegisterForm;
