import React, { useEffect, useRef, useState } from 'react';
import {
    Box, useMediaQuery, Chip, Dialog, DialogTitle,
    DialogContent, Grid, Card, Table, TableBody, TableHead,
    TableCell, TableRow, Stack, Typography, Divider, FormLabel, Radio,
    IconButton, Button, Autocomplete, TextField, FormControl, FormControlLabel, RadioGroup,
    Tooltip,
} from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import CalendarStyled from '../../sections/apps/calendar/CalendarStyled';
import Toolbar from '../../sections/apps/calendar/Toolbar';
import axios from 'axios';
import { base_Url } from '../BaseUrl/ApiUrl';
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from 'moment';
import { Toaster, toast } from "react-hot-toast";
import _ from 'lodash'
import dayjs from 'dayjs';
import './calender.css'
import thumbs from '../../assets/icons8-tap-gesture.gif'
import mark from '../../assets/icons8-question-mark.gif'
import MainCard from '../../components/MainCard';
// import MainCard from '../../components/MainCard';


const Calendar = ({ open, setOpen, selectedCourseInfo, coursedetails, setCoursedetails, zones, setZones }) => {

    const fetchZones = async () => {
        try {
            const response = await axios.post(`${base_Url}/getAllBranches`, {
                courseid: selectedCourseInfo.courseid
            })
            if (response.data.status) {
                const data = await response?.data?.response?.map((item) => {
                    return {
                        id: item?.applocationid,
                        label: item?.locationname
                    }
                })

                // const newlo = await data?.filter(item => item?.id !== 4)

                // console.log('newlo', newlo)
                setZones(data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchZones()
    }, [open])

    console.log('bbb>>>', coursedetails)
    const [EventsInfo, setEventsInfo] = useState([])

    const getMatchingCourse = (courseid, detailsArray) => {
        return detailsArray?.find(course => course.productid == courseid);
    };
    const matchingCourse = getMatchingCourse(selectedCourseInfo.courseid, coursedetails);
    console.log('matchingCourse123', matchingCourse)

    console.log('selectedCourseInfo >>>>>>>>', selectedCourseInfo)

    const getMatchingZone = (courseid, detailsArray) => {
        console.log('coursedetails >>>>>>>>', coursedetails)
        return detailsArray?.find(course => course?.productid == courseid);
    };

    const matchingZone = getMatchingZone(selectedCourseInfo.courseid, coursedetails);
    console.log('matchingZone', matchingZone)

    const fetchEvents = async () => {
        try {
            const response = await axios.post(`${base_Url}/getaddonschedules`, {
                "addonid": matchingCourse?.addonid,
                "courseid": matchingCourse?.packageid
            })
            if (response.data.message) {
                console.log('response', response?.data?.response)
                setEventsInfo(response?.data?.response)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (matchingCourse) {
            fetchEvents()
        }

    }, [matchingCourse])

    // end 


    // county call
    const [countys, setCountys] = useState([])
    const fetchCounty = async () => {
        try {
            const response = await axios.post(`${base_Url}/getCounty`, {
                "countyid": ''
            })
            if (response.status) {
                const result = response.data.response.map((item) => {
                    return {
                        id: item.appcountyid,
                        label: item?.countyname
                    }
                })
                setCountys(result)


            }
        } catch (error) {
            console.log('error', error)
        }

    }
    useEffect(() => {
        fetchCounty()
    }, [])


    // useEffect(() => {
    //     setCoursedetails(coursedetails.map(course =>
    //         course.productid === selectedCourseInfo.courseid
    //             ? { ...course, countyid: countys[0].id, addonid: null }
    //             : course
    //     ));
    // }, [countys])

    console.log('countys', countys)



    // county end 







    const [loading, setLoading] = useState(true);
    const matchDownSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const [calendarView, setCalendarView] = useState(matchDownSM ? 'listWeek' : 'dayGridMonth');
    const [events, setEvents] = useState([]);


    // 1-combo    2 -single
    const fetchData = async () => {
        try {
            const response = await axios.post(`${base_Url}/getcoursedatesfilter`,
                {
                    "courseid": selectedCourseInfo?.courseid,
                    "countyId": 0,
                    "type": selectedCourseInfo?.type,
                    "combo": selectedCourseInfo?.combo
                }
            );

            if (response.data.status === true) {
                const data = response?.data?.response[0]?.dates;

                console.log('data >>', data)


                const result = data.reduce((acc, item) => {
                    const transformed = item?.details?.map((subarray, index) => ({
                        id: item.addonid,
                        start: subarray[0]?.date,
                        end: subarray[subarray.length - 1]?.date,
                        allDay: false,
                        color: 'white',
                        scheduletype: item?.scheduletype,
                        countyname: item?.countyname,
                        productid: item?.productid
                    }));
                    return acc.concat(transformed);
                }, []);

                const assignSlotTitles = (result) => {
                    const idToIndexMap = new Map();
                    let slotCounter = 1;

                    return result.map((item) => {
                        let index;
                        if (idToIndexMap.has(item.id)) {
                            index = idToIndexMap.get(item.id);
                        } else {
                            index = slotCounter++;
                            idToIndexMap.set(item.id, index);
                        }

                        const titleHelper = (data) => {

                            if (data?.productid == 2) {
                                return `3 Day Session`
                            }
                            else if (data?.productid == 3 && data?.scheduletype == `oneday`) {
                                return `9.30am - 4.30pm`
                            }
                            else if (data?.productid == 3 && data?.scheduletype == `twoday`) {
                                return `6pm - 9pm`
                            }
                            else if (data?.productid == 4) {
                                return `${data?.scheduletype}`
                            }
                        }

                        return {
                            id: item.id,
                            title: item?.productid == 4 ? `slot${index}` : titleHelper(item),
                            // title: '',
                            start: item.start,
                            end: dayjs(item.end).add(5, 'hour').add(30, 'minute').toISOString(),
                            allDay: item.allDay,
                            color: item.color,
                            scheduletype: item?.scheduletype,
                            countyname: item?.countyname,
                            priority: item?.scheduletype == `oneday` ? 1 : 2
                        };
                    });
                };


                const final = assignSlotTitles(result);
                console.log('final', final)
                console.log('result', result)


                setEvents(final);
                setLoading(false);
            } else {
                setEvents([])
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedCourseInfo, coursedetails]);

    const calendarRef = useRef(null);
    const [date, setDate] = useState(new Date());



    useEffect(() => {
        if (calendarRef.current) {
            console.log('calling');
            const calendarApi = calendarRef.current.getApi();
            calendarApi.removeAllEvents();
            calendarApi.addEventSource(events);
        }
    }, [events]);

    useEffect(() => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.changeView(calendarView);
        }
    }, [calendarView]);

    const handleDateToday = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView) => {
        setCalendarView(newView);
    };

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const handleRangeSelect = (arg) => {
        const calendarEl = calendarRef.current;
        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.unselect();
        }
    };

    const [selectedSlot, setSelectedSlots] = useState({})

    console.log(selectedSlot);


    const handleEventSelect = (arg) => {
        // setClickedEventId(arg.event.id);


        console.log('arg', arg?.event?._def?.extendedProps)

        setSelectedSlots(arg?.event?._def?.extendedProps)

        // setCoursedetails(prevDetails => {
        //     return prevDetails.map(course => {
        //         if (course.productid === selectedCourseInfo.courseid) {
        //             return { ...course, addonid: arg.event.id };
        //         }
        //         return course;
        //     });
        // });

        setCoursedetails(prevDetails => {
            return prevDetails.map(course => {
                if (course.productid === selectedCourseInfo.courseid) {
                    let updatedCourse = { ...course, addonid: arg.event.id };

                    if (selectedCourseInfo?.type == 3) {
                        const type = arg?.event?._def?.extendedProps?.scheduletype;
                        updatedCourse.classmode = type == 'twoday' ? 1 : updatedCourse.classmode;
                        updatedCourse.scheduletype = type;
                    }
                    if (selectedCourseInfo?.type == 4) {
                        updatedCourse.classmode = 1;
                    }

                    return updatedCourse;
                }
                return course;
            });
        });


        // if (selectedCourseInfo?.type == 3) {
        //     const type = arg?.event?._def?.extendedProps?.scheduletype
        //     setCoursedetails(coursedetails.map(course =>
        //         course.productid == selectedCourseInfo.courseid
        //             ? { ...course, classmode: type == 'twoday' ? 1 : 2 }
        //             : course
        //     ))
        // }




        // const selectedEvent = {
        //     addonid: arg.event.id,
        //     title: arg.event.title,
        // };
        // console.log('Selected Event:', selectedEvent);
    };

    const handleEventUpdate = async ({ event }) => {
        console.log('Updating event', event);
    };


    const handleClose = () => {

        // if (selectedCourseInfo?.combo == 1) {
        // if (selectedCourseInfo?.type == 4) {
        //     if (!matchingZone?.countyid) {
        //         toast.error('Please select a county')
        //         return
        //     }
        // }
        if (!matchingZone?.addonid) {
            toast.error('Please select a Slot')
            return
        }

        if (selectedCourseInfo?.type !== 4) {


            if (!matchingZone?.classmode) {
                toast.error('Please select the class mode')
                return
            }


            if (matchingZone?.classmode == 2) {
                if (!matchingZone?.locationid) {
                    toast.error('(for in-person classes): Please select the location.')
                    return
                }
            }
        }

        // }
        // else {
        //     if (selectedCourseInfo?.type == 4) {
        //         if (!matchingZone?.countyid) {
        //             toast.error('Please select a county')
        //             return
        //         }
        //     }
        //     if (!matchingZone?.addonid) {
        //         toast.error('Please select a Slot')
        //         return
        //     }

        //     if (selectedCourseInfo?.type !== 4) {


        //         if (!matchingZone?.classmode) {
        //             toast.error('Please select the class mode')
        //             return
        //         }


        //         if (matchingZone?.classmode == 2) {
        //             if (!matchingZone?.locationid) {
        //                 toast.error('(for in-person classes): Please select the location.')
        //                 return
        //             }
        //         }
        //     }
        // }

        setLoading(true)
        setOpen(false)
    }



    const edata = EventsInfo?.map(item => ({
        ...item,
        date: item.date.substring(0, 10),
        starttime: moment(item.starttime).format('hh:mm A'),
        endtime: moment(item.endtime).format('hh:mm A')
    }));








    console.log('EventsInfo', EventsInfo)





    // if (loading) return 'loading....';

    const [currentClassMode, setCurrentClassMode] = useState(null);
    // const [isDriverEdu, setIsDriverEdu] = useState(false)



    useEffect(() => {
        const foundClassMode = coursedetails?.find(item => item?.productid == selectedCourseInfo?.courseid);
        setCurrentClassMode(foundClassMode);

    }, [coursedetails, selectedCourseInfo]);

    console.log("v1 class mode", currentClassMode)
    console.log("v1 class id", currentClassMode?.classmode)

    console.log("v1 class info", coursedetails)
    return (
        <>
            <Toaster />
            <Dialog
                open={open}
                keepMounted
                fullScreen={matchDownSM}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle
                >
                    <Stack direction='row' justifyContent='center' alignItems='center'>
                        {/* <Typography variant='h5' sx={{ color: 'Black !important' }}>
                            Slots
                        </Typography> */}


                        <Chip
                            label={`${matchingCourse?.productname} Course`}
                            variant='filled'
                            color='primary'
                            size='large'
                        />
                        {/* <Button
                            variant='contained'
                            color='error'
                            onClick={() => setOpen(false)}>
                            Close

                        </Button> */}
                    </Stack>

                </DialogTitle>
                <DialogContent>
                    <Grid container sx={{ p: 1, mb: 1 }} spacing={3}>
                        {/* <Card sx={{ p: 2, border: '1px solid hsla(0,0%,84%,.3)', mt: 1 }}> */}

                        {/* </Card> */}

                        {
                            selectedCourseInfo?.type == 4 &&

                            <Grid item xs={12} md={4.5}>
                                <Stack direction={'row'} spacing={1}>
                                    <Typography>Class mode : </Typography>
                                    <Chip label='Zoom' color='success' variant='outlined' size='small' />
                                </Stack>

                                {/* {selectedCourseInfo?.type === 4 && (
                                    <Card sx={{ p: 2, border: '1px solid hsla(0,0%,84%,.3)', mt: 1 }}>



                                        <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>

                                            <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
                                                <Typography className="blinking-text" sx={{ color: '#f53936 !important', fontWeight: '900 !important', fontSize: '22px !important' }}>
                                                    Select County
                                                </Typography>
                                                <img src={thumbs} alt='dd' />

                                            </Stack>
                                            <Typography variant='body1' className="bouncing-text">
                                                (Slots available based on county selection)
                                            </Typography>
                                        </Stack>

                                        <Divider sx={{ mt: 1 }} />



                                        <div className="county-list">
                                            {countys.map((county) => (
                                                <div
                                                    key={county.id}
                                                    className={`county-option ${county.id === matchingZone?.countyid ? 'selected' : ''}`}
                                                    onClick={() =>
                                                        setCoursedetails(coursedetails.map(course =>
                                                            course.productid === selectedCourseInfo.courseid
                                                                ? { ...course, countyid: county.id, addonid: null }
                                                                : course
                                                        ))
                                                    }
                                                >
                                                    {county.label}
                                                </div>
                                            ))}
                                        </div>


                                    </Card>
                                )} */}

                                <MainCard>
                                    {
                                        selectedSlot && Array.isArray(selectedSlot?.countyname) ? <Stack direction={'column'}>
                                            {console.log("🔥 ", selectedSlot)}
                                            {selectedSlot?.countyname?.map((val, index) => {
                                                return <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                                                    <Typography>{index + 1} :- </Typography>
                                                    <Typography>{val}</Typography>
                                                </Stack>
                                            })}
                                        </Stack> : <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            {console.log("🔥 ", selectedSlot)}
                                            <Typography>{selectedSlot?.countyname ? selectedSlot?.countyname : ""}</Typography>
                                        </Stack>
                                    }
                                </MainCard>



                                <Card sx={{ p: 2, border: '1px solid hsla(0,0%,84%,.3)', mt: 2 }}>
                                    <Typography variant='h5' sx={{ color: 'black !important' }}> Schedules</Typography>
                                    <Divider sx={{ mb: 0, pb: 1 }} />
                                    <Box sx={{ overflowX: 'auto' }}>
                                        <Table sx={{ p: 1, m: 1 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        #
                                                    </TableCell>
                                                    <TableCell>
                                                        Date
                                                    </TableCell>
                                                    <TableCell>
                                                        Start time
                                                    </TableCell>
                                                    <TableCell>
                                                        End time
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>


                                                {
                                                    edata?.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell >
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell >

                                                                {item?.date}
                                                            </TableCell>
                                                            <TableCell >

                                                                {item?.starttime}
                                                            </TableCell>
                                                            <TableCell >

                                                                {item?.endtime}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </Box>
                                    <Button
                                        variant='contained'
                                        color='success'
                                        sx={{ float: 'right' }}
                                        onClick={() => handleClose()}>
                                        Proceed

                                    </Button>
                                </Card>



                            </Grid>
                        }





                        <Grid item xs={12} md={7.5}>
                            {/* <Card sx={{ p: 1, border: '1px solid hsla(0,0%,84%,.3)' }}> */}
                            <Box sx={{ mb: 1 }}>
                                <CalendarStyled>
                                    <Toolbar
                                        date={date}
                                        view={calendarView}
                                        onClickNext={handleDateNext}
                                        onClickPrev={handleDatePrev}
                                        onClickToday={handleDateToday}
                                        onChangeView={handleViewChange}
                                    />

                                    <FullCalendar
                                        weekends
                                        editable={false}
                                        droppable={false}
                                        selectable
                                        events={[]}
                                        ref={calendarRef}
                                        rerenderDelay={10}
                                        initialDate={date}
                                        // initialView={calendarView}
                                        dayMaxEventRows={3}
                                        eventDisplay="block"
                                        headerToolbar={false}
                                        allDayMaintainDuration
                                        eventResizableFromStart
                                        select={handleRangeSelect}
                                        eventDrop={handleEventUpdate}
                                        eventClick={handleEventSelect}
                                        eventResize={handleEventUpdate}
                                        height={matchDownSM ? 'auto' : 820}
                                        plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                                        // timeZone="UTC"
                                        eventContent={(eventInfo) => (
                                            <Tooltip title={eventInfo.event.title}>
                                                <Chip
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        background: matchingCourse?.addonid === eventInfo.event.id ? 'red' : 'white',
                                                        fontWeight: matchingCourse?.addonid === eventInfo.event.id ? 'normal' : 'bold', // Make outlined variant text bold
                                                        borderWidth: matchingCourse?.addonid === eventInfo.event.id ? '1px' : '2px',  // Thicker border for outlined variant
                                                        borderColor: matchingCourse?.addonid === eventInfo.event.id ? 'transparent' : 'green', // Success border color
                                                    }}
                                                    label={eventInfo.event.title}
                                                    variant={matchingCourse?.addonid === eventInfo.event.id ? 'filled' : 'outlined'}
                                                    color={matchingCourse?.addonid === eventInfo.event.id ? "error" : "success"}
                                                />
                                            </Tooltip>
                                        )}
                                        displayEventTime={false}
                                        eventOrder="priority"
                                    />
                                </CalendarStyled>
                            </Box>
                            {/* </Card> */}
                        </Grid>


                        {

                            selectedCourseInfo?.type !== 4 &&
                            <Grid item xs={12} md={4.5}>

                                <Card sx={{ p: 2, border: '1px solid hsla(0,0%,84%,.3)', mt: 2 }}>


                                    <Stack>
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography variant='h5' sx={{ color: 'green !important', mb: 3 }} className="bouncing-text">
                                                Did You Know
                                            </Typography>
                                            <img
                                                src={mark}
                                                alt='fgf'
                                                style={{
                                                    height: '30px',
                                                    width: '30px'
                                                }}
                                            />
                                        </Stack>


                                        <Typography sx={{ fontSize: '16px !important', fontWeight: 500 }}>DDS - Certified live remote or In person classess - </Typography>
                                        <Typography sx={{ fontSize: '16px !important', fontWeight: 500, color: '#1976d2 !important' }}>Certificates are extactly the same from GA.dept. of driver services.</Typography>



                                        {
                                            selectedCourseInfo?.type == 3 &&
                                                coursedetails?.find(item => item?.productid == selectedCourseInfo?.courseid)?.scheduletype == 'twoday' ?
                                                <Stack direction={'row'} spacing={1}>
                                                    <Typography>Class mode : </Typography>
                                                    <Chip label='Zoom' color='success' variant='outlined' size='small' />
                                                </Stack>
                                                :
                                                <>
                                                    <Typography sx={{ color: 'black !important', mt: 2 }}>  Class mode </Typography>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            disabled={selectedCourseInfo?.type == 3 &&
                                                                coursedetails?.find(item => item?.productid == selectedCourseInfo?.courseid)?.scheduletype == 'twoday' ?
                                                                true : false}
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            value={currentClassMode?.classmode}
                                                            // value={coursedetails?.find(item => item?.productid == selectedCourseInfo?.courseid)?.classmode}
                                                            onChange={(e) =>
                                                                setCoursedetails(coursedetails.map(course =>
                                                                    course.productid == selectedCourseInfo.courseid
                                                                        ? { ...course, classmode: e.target.value }
                                                                        : course
                                                                ))
                                                            }
                                                            name="status"
                                                        >


                                                            <FormControlLabel value={1} control={<Radio />} label="Zoom"

                                                            />
                                                            <FormControlLabel value={2} control={<Radio />} label="In person (Location)"
                                                                disabled={selectedCourseInfo?.type == 3 &&
                                                                    coursedetails?.find(item => item?.productid == selectedCourseInfo?.courseid)?.scheduletype == 'twoday' ?
                                                                    true : false}

                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>

                                        }
                                    </Stack>



                                    {
                                        matchingZone?.classmode == 2 &&
                                        <Stack sx={{ mt: 2 }}>
                                            <Typography sx={{ color: 'black !important' }}>Location</Typography>
                                            <Autocomplete
                                                fullWidth
                                                size="small"
                                                id="model"
                                                options={zones ? zones : []}
                                                value={zones?.find((option) => option?.id == matchingZone?.locationid) || null}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(event, val) => {
                                                    if (val) {
                                                        setCoursedetails(coursedetails.map(course =>
                                                            course.productid === selectedCourseInfo.courseid
                                                                ? { ...course, locationid: val.id }
                                                                : course
                                                        ));
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Location" size='small' fullWidth />
                                                )}
                                                autoHighlight={true}
                                            />

                                        </Stack>
                                    }


                                </Card>




                                {/* {
                                    selectedCourseInfo?.type == 4 &&
                                    <Card sx={{ p: 2, border: '1px solid hsla(0,0%,84%,.3)', mt: 2 }}>

                                        <Autocomplete
                                            id="countys"
                                            options={countys ? countys : []}
                                            value={countys?.find((option) => option?.id == matchingZone?.countyid) || null}
                                            // value={genderArray?.find((option) => option?.label == formData?.gender) || null}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, val) => {
                                                if (val) {
                                                    setCoursedetails(coursedetails.map(course =>
                                                        course.productid === selectedCourseInfo.courseid
                                                            ? { ...course, countyid: val.id, addonid: null }
                                                            : course
                                                    ));
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} placeholder={"county"} size='small'
                                                    label={"county"} required

                                                />
                                            )}
                                            sx={{ mt: 1 }}
                                            autoHighlight={true}
                                        />

                                        <Typography sx={{ mt: 2 }}>Note : Slots will be available based on county selection.</Typography>
                                    </Card>
                                } */}






                                <Card sx={{ p: 2, border: '1px solid hsla(0,0%,84%,.3)', mt: 2 }}>
                                    <Typography variant='h5' sx={{ color: 'black !important' }}> Schedules</Typography>
                                    <Divider sx={{ mb: 0, pb: 1 }} />

                                    <Box sx={{ overflowX: 'auto' }}>


                                        <Table sx={{ p: 1, m: 1 }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        #
                                                    </TableCell>
                                                    <TableCell>
                                                        date
                                                    </TableCell>
                                                    <TableCell>
                                                        start time
                                                    </TableCell>
                                                    <TableCell>
                                                        end time
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>


                                                {
                                                    edata?.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell >
                                                                {index + 1}
                                                            </TableCell>
                                                            <TableCell >

                                                                {item?.date}
                                                            </TableCell>
                                                            <TableCell >

                                                                {item?.starttime}
                                                            </TableCell>
                                                            <TableCell >

                                                                {item?.endtime}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </Box>
                                    <Button
                                        variant='contained'
                                        color='success'
                                        sx={{ float: 'right' }}
                                        onClick={() => handleClose()}>
                                        Proceed
                                        {/* <IoMdCloseCircleOutline sx={{ fontSize: '40px' }} /> */}
                                    </Button>
                                </Card>



                            </Grid>
                        }







                    </Grid>


                </DialogContent>
            </Dialog >
        </>

    );
};

export default Calendar;



